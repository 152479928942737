<template>
  <div>
    <b-card
      no-body
      class="card-statistics"
    >
      <b-card-header>
        <b-card-title>{{ $t('indicacao.rede.titleCardStatistics') }}</b-card-title>
      </b-card-header>
      <b-card-body class="statistics-body">
        <b-row v-if="estatistica">
          <b-col
            xl="3"
            sm="6"
          >
            <b-media no-body>
              <b-media-aside

                class="mr-2"
              >
                <b-avatar
                  size="48"
                  variant="light-success"
                >
                  <feather-icon
                    size="24"
                    icon="UserIcon"
                  />
                </b-avatar>
              </b-media-aside>
              <b-media-body class="my-auto">
                <h4 class="font-weight-bolder mb-0">
                  {{ estatistica.cadastroAtivo }}
                </h4>
                <b-card-text class="font-small-3 mb-0">
                  {{ $t('indicacao.cardTextAtivos') }}
                </b-card-text>
              </b-media-body>
            </b-media>
          </b-col>
          <b-col
            xl="3"
            sm="6"
          >
            <b-media no-body>
              <b-media-aside

                class="mr-2"
              >
                <b-avatar
                  size="48"
                  variant="light-info"
                >
                  <feather-icon
                    size="24"
                    icon="UserIcon"
                  />
                </b-avatar>
              </b-media-aside>
              <b-media-body class="my-auto">
                <h4 class="font-weight-bolder mb-0">
                  {{ estatistica.cadastroPendente }}
                </h4>
                <b-card-text class="font-small-3 mb-0">
                  {{ $t('indicacao.cardTextPendentes') }}
                </b-card-text>
              </b-media-body>
            </b-media>
          </b-col>
          <b-col
            v-show="false"
            xl="3"
            sm="6"
          >
            <b-media no-body>
              <b-media-aside

                class="mr-2"
              >
                <b-avatar
                  size="48"
                  variant="light-danger"
                >
                  <feather-icon
                    size="24"
                    icon="UserIcon"
                  />
                </b-avatar>
              </b-media-aside>
              <b-media-body class="my-auto">
                <h4 class="font-weight-bolder mb-0">
                  {{ estatistica.cadastroVencido }}
                </h4>
                <b-card-text class="font-small-3 mb-0">
                  {{ $t('indicacao.cardTextVencidos') }}
                </b-card-text>
              </b-media-body>
            </b-media>
          </b-col>
        </b-row>
      </b-card-body>
    </b-card>
    <b-card
      no-body
    >
      <b-table
        ref="refListTable"
        :items="dataList"
        responsive
        :fields="tableColumns"
        primary-key="id"
        :sort-by.sync="sortBy"
        show-empty
        empty-text="Nenhum registro encontrado"
        :sort-desc.sync="isSortDirDesc"
        striped
        :busy.sync="loadingOverlayGrid"
      >
        <template #cell(dataCriacao)="data">
          {{ formatDateBR(data.value) }}
        </template>

        <template #cell(status)="data">
          <b-badge :variant="getStatusColor(data.value)">
            {{ data.value }}
          </b-badge>
        </template>

      </b-table>
      <div class="mx-2 mb-2">
        <b-row>

          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">
              {{ $t('indicacao.extrato.alertTextMuted', { from: paginationMeta.from, to: paginationMeta.to, of: paginationMeta.of }) }}
            </span>
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >

            <b-pagination
              v-model="currentPage"
              :total-rows="totalCount"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>

          </b-col>

        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard, BTable, BPagination, BRow, BCol, BCardBody, BMedia, BMediaAside, BMediaBody, BAvatar, BCardHeader, BCardTitle, BCardText, BBadge,

} from 'bootstrap-vue'
import store from '@/store'
import utilsMixin from '@/mixins/utilsMixin'
import { formatDateBR, formatCurrencyUSD } from '@/utils/filter'
import redeStoreModule from './redeStoreModule'

const REDE_EXTRATO_STORE_MODULE_NAME = 'indicacao-rede'

export default {

  components: {
    BCard,
    BCardBody,
    BCardText,
    BTable,
    BPagination,
    BRow,
    BCol,
    BMedia,
    BMediaAside,
    BMediaBody,
    BAvatar,
    BCardHeader,
    BCardTitle,
    BBadge,
  },

  mixins: [utilsMixin],

  data() {
    return {
      formatCurrencyUSD,
      formatDateBR,
      loadingOverlayGrid: false,
      perPage: 10,
      totalCount: 0,
      currentPage: 1,
      sortBy: 'dataCriacao,desc',
      dataListOriginal: null,
      isSortDirDesc: true,
      estatistica: null,
    }
  },

  computed: {
    tableColumns() {
      return [
        { key: 'dataCriacao', label: this.$i18n.t('indicacao.dataCriacao') },
        { key: 'nome', label: this.$i18n.t('indicacao.rede.nome') },
        { key: 'email', label: this.$i18n.t('indicacao.rede.email') },
        { key: 'nivel', label: this.$i18n.t('indicacao.rede.nivel') },
        { key: 'status', label: this.$i18n.t('indicacao.status') },
      ]
    },
    paginationMeta() {
      const localItemsCount = this.$refs.refListTable ? this.$refs.refListTable.localItems.length : 0
      return {
        from: this.perPage * (this.currentPage - 1) + (localItemsCount ? 1 : 0),
        to: this.perPage * (this.currentPage - 1) + localItemsCount,
        of: this.totalCount,
      }
    },
    windowWidth() {
      return this.$store.state.app.windowWidth
    },

    dataList() {
      // Calculate the start and end indices based on currentPage and perPage
      const startIndex = (this.currentPage - 1) * this.perPage
      const endIndex = startIndex + this.perPage

      if (this.dataListOriginal) {
        // Slice the original data array to get the items for the current page
        return this.dataListOriginal.slice(startIndex, endIndex)
      }
      return null
    },
  },

  created() {
    if (!store.hasModule(REDE_EXTRATO_STORE_MODULE_NAME)) store.registerModule(REDE_EXTRATO_STORE_MODULE_NAME, redeStoreModule)
    this.getEstatistica()
    this.fetchList()
  },

  beforeDestroy() {
    if (store.hasModule(REDE_EXTRATO_STORE_MODULE_NAME)) store.unregisterModule(REDE_EXTRATO_STORE_MODULE_NAME)
  },

  methods: {

    getStatusColor(value) {
      if (value === 'Pendente') return 'warning'
      if (value === 'Ativo') return 'success'
      if (value === 'Vencido') return 'danger'
      return 'warning'
    },

    getEstatistica() {
      store
        .dispatch('indicacao-rede/getEstatistica')
        .then(response => {
          this.estatistica = response.data
        })
    },

    async fetchList() {
      this.loadingOverlayGrid = true
      store
        .dispatch('indicacao-rede/getRede', {
          size: this.perPage,
          page: this.currentPage - 1,
          sortBy: this.sortBy,
          sortDesc: this.isSortDirDesc,
        })
        .then(response => {
          this.dataListOriginal = response.data
          this.totalCount = this.dataListOriginal.length
        }).finally(() => {
          this.loadingOverlayGrid = false
        })
    },
  },
}
</script>
